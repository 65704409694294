import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validation from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'

import SelectPackages from '@/components/forms/SelectPackages';

export default {
  name: 'courses-graduate-work',
  mixin: [mixins, course, validation],
  data () {
    return {
      valid: false,
      payload: {
        description: '',
        name: '',
        maxPoints: '',
        statusId: '',
        displayTo: '',
        openingDay: '',
        openingAt: '',
        // repeatedly: '',
        // useCustomRules: false,
        rules: '',
        packages: '',
        subtitle: '',
        uniqueDate: false,
        openingPoints: ''
      }
    }
  },
  components: {
    MainEditor,
    ImageFile,
	SelectPackages
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      packagesList: 'coursesPackages/list',
      packagesListLoading: 'coursesPackages/listLoading',
      currentItem: 'coursesGraduate/content',
      isContentLoading: 'coursesGraduate/contentLoading',
      isUpdateContentLoading: 'coursesGraduate/updateContentLoading',
      updateImageLoading: 'coursesGraduate/updateImageLoading',
      setting: 'courses/setting'
    })
  },
  created () {
    if (!this.packagesList.length) {
      this.fetchPackages({ id: this.$route.params.course_id })
    }
    this.fetchContent({ course_id: this.$route.params.course_id }).then(() => {
      this.setContent()
    })
    if (!this.currentItem.hasOwnProperty('id')) {
      this.fetchContent({ course_id: this.$route.params.course_id }).then(() => {
        this.setContent()
      })
    } else {
      this.setContent()
    }
    this.setCourseStep(6)
  },
  methods: {
    ...mapActions({
      fetchPackages: 'coursesPackages/PACKAGES_GET_LIST',
      fetchContent: 'coursesGraduate/GRADUATE_GET_CONTENT',
      updateContent: 'coursesGraduate/GRADUATE_UPDATE_CONTENT',
      updateImage: 'coursesGraduate/UPDATE_IMAGE'
    }),
    ...mapMutations({}),

    updateImageLocal (params) {
      params.append('coursesId', this.$route.params.course_id)
      params.append('graduateWorkId', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      for (const i in data) {
        formData[snakeCase(i)] = data[i]
      }
      formData.display_to = this.formattedDate(this.payload.displayTo)
      this.payload.openingAt ? formData.opening_at = this.formattedDate(this.payload.openingAt) : ''
      formData._method = 'patch'
      return formData
    },
    setContent () {
      Object.assign(this.payload, this.currentItem)
      this.payload.packages = this.currentItem.packages.data.map(e => e.id)
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        this.updateContent(this.createRequestPayload()).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    },
    back () {
      this.goToStep('courses.contents', 5)
    }
  }
}
